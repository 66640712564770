/* ===============

        Generic Template Basic Styling

=============== */

html {
    margin: 0;
}

html, body:not(.wp-admin):not(.mce-content-body) {
    height: 100%;
}

body:not(.wp-admin):not(.mce-content-body) {
    background-color: $brand-quaternary;
    &.weddings, &.parent-pageid-10 {
        background-color: $brand-quinary;
    }
}

body.admin-bar {
    .balbirnieMasterWrap header.banner {
        margin-top:32px; 
    }
    #mp-menu {
        top:32px; 
    }
}

blockquote {
    border-left: 5px solid rgb(53, 84, 148) !important;
}

#contactMap {
    width: 100%;
    height: 100%;
}

.mainSlider .slides > li{
    background-position: center center;
    background-size: cover;
}
.flexslider, .mainSlider, .mobileFlexSlider  {
    background: transparent;
    border: 0;
    overflow: hidden;
    position: relative;
    .flex-direction-nav >li {
            
           a{
               display: block;
               width: 10px;
               text-indent: 50px;
               overflow: hidden;
               &:before{
                   display: block;
                   font-family: 'fontawesome' !important;
                   content: '\f104';
                   text-indent: 0;
               }
           }
           .flex-prev{
               &:before{
                    content: '\f104';
                }
           }
           .flex-next{
               text-indent: 100px;
               overflow: hidden;
               &:before{
                    content: '\f105';
                    text-indent: 0;
                }
           }
    }
    &:hover{
       .flex-direction-nav >li {
           .flex-prev{
                left: 20px;
                @media (min-width: $screen-xs-min){
                    left: 40px;
                }
            }
            .flex-next{
                right: 20px;
                @media (min-width: $screen-xs-min){
                    right: 40px;
                }
            }
        }
    }
    .flex-direction-nav{
        pointer-events: none;
        position: absolute;
        top:0;
        left: -4%;
        width: 108%;
        height: 100%;
        z-index: 9999;
        >li {
          pointer-events: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          &.flex-nav-next{
              right: 0;
          }
        }
    }
}
.flex-direction-nav a, .flex-direction-nav a:before {
    color: rgba(255,255,255,1) !important;
    text-shadow: 1px 1px 0 hsla(0,0%,0%,1) !important;
    position: relative;
    z-index: 999;
}

// Mobile gallery
.ggWrap.mobileFlexSlider{
    margin-left: - $grid-gutter-width / 2;
    margin-right: - $grid-gutter-width / 2;
    >.slides{
        height: 100%;
        >li{
            height: 100%;
            >div{
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
        }
    }
}

.balbirnieMasterWrap {
    height: 100%;
    min-height: 100%;

    header.banner {
        background-color: $brand-primary;
        /* height: 10vh; */
        &.weddings {
            border-bottom: 5px solid #422c89;
        }
        .brand {
            img {
                @include breakpoint(sm) {
                    margin: 30px 0px 30px 60px;
                    transition: 0.5s margin ease; 
                }
            }
        }
        p {
            color: #fff;
        }
        h2, h3, h4 {
            color: #fff;
        }
        h4 {
            margin: 35px -15px 0;
            font-size: 13px;
            text-align: center;
            @media (min-width:$screen-sm-min) and (max-width:$screen-md-min - 1){
                margin-top: 37px;
                text-align: left;
                .fa-phone{
                    position: absolute;
                    left: -15px;
                }
            }
            @media (min-width:$screen-md-min){
                font-size: 14px;
                text-align: left;
            }
            @media (min-width:$screen-lg-min){
                font-size: 18px;
            }
        }
        .socialCons {
            margin: 35px 0 0;
            a {
                @include speed();
                color: #fff;
                margin: 0px 5px;
                &:last-of-type {
                    margin: 0px;
                }
                &:hover {
                    color: #FFC33C;
                    text-decoration: none;
                }
                span {					
                    font-size: 25px;
                    display: inline-block;
                }
            }
        }
        .menuWrap {
            @media (max-width:$screen-sm-min - 1){
                position: absolute;
                right: -95%;
                top: -112px;
            }
            &:hover {
                #nav-icon3 span  {
                    background: #bda8ff;
                }
            }
            .menuMessage {
                @include speed();
                color: #fff;
                font-size: 18px;
                font-family: "adobe-text-pro",sans-serif;
                
                //right: 97px;
                top: 34px;
                @media (max-width:$screen-sm-min - 1){
                    display: none;
                    width: 100%;
                    text-align: center;
                }
                
                @media (min-width:$screen-sm-min){
                    font-size: 14px;
                }
                @media (min-width:$screen-lg-min){
                    position: relative;
                    float: left;
                    font-size: 16px;
                    top:5px;
                    padding-left: 5px;
                }
                @media (min-width:$screen-lg-min + 100){
                     padding-left: 15px;
                }
            }
            .menu {
                #nav-icon3 {
                    position: relative;
                   
                }
                p {
                    position: relative;
                    span {
                        @include speed();
                        position: absolute;
                        top: 3px;
                        left: -65px;
                        text-transform: uppercase;
                    }
                }
            }
            .menuMaster {
                display: block;
                padding: 10px 5px 0;
                position: relative;
                background: $brand-primary;
                transition: 0.3s background ease; 
                @media (min-width:$screen-sm-min){
                    padding: 18px 0 18px 2px;
                    text-align: center;
                }
                @media (min-width:$screen-lg-min){
                    overflow: hidden;
                    position: relative;
                    padding: 29px 0;
                }
            }
            .menuMaster:hover {
                cursor: pointer;
                background: $brand-secondary;
            }
        }
    }

    .contentGrid {
        @include breakpoint(sm) {
            position: relative;
            top: 10vh;
        }
        .pageContents {
            margin-bottom: 60px;
            img {
                margin: 20px auto;
                max-width: 100%;
                padding: 10px;
                border: 1px solid rgba(21,21,21,0.10);
            }
			ul {
				li {
					font-size: 18px;
					font-family: "adobe-text-pro",sans-serif;
				}
			}
        }
        .cgWrap {
            h2,h3{
                font-weight: 300;
            }
            /* height: 90vh;
            overflow-y: scroll;*/
            @include breakpoint(sm) {
                padding: 0px 30px 30px;
                position: relative;
                width: 90%; 
                margin: 0 auto; 
                text-align: justify;
            }
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: $brand-primary;
            }
            .hpBoxWrap {
                border: 1px solid #666;
                padding: 20px;
                margin: 20px auto 30px;
                width: 100%;
                div {
                    background-color: #cbc9c7;
                    padding: 20px;
                    img {
                        margin: 0px 0px 15px;
                    }
                    h2 {
                        margin: 0px;
                        font-size: 25px;
                        line-height: 25px;
                    }
                }
            }
            .introMessage {
                font-size: 33px;
                font-weight: 100;
                line-height: 40px;
                margin: 30px 0;
            }
            /* Internal Pages */
            .breadcrumbs {
                a {
                    color: $brand-secondary;
                }
                .active {
                    color: darken($brand-quaternary, 15%);
                }
            }
            /* Weddings */
        }
        .featureWrap {
            border: 1px solid rgba(21,21,21,0.10);
            padding: 20px;
            margin: 0 0 25px;
			&.thePeopleWrap {
				padding: 10px;
			}
            .featureBox {
                padding: 20px;
                background-color: #fff;
				text-align: left;
				&.thePeople {
					padding: 15px;
					@media (min-width:$screen-md-min){
						min-height: 380px;
					}
				}
                h4 {
                    margin: 10px 0px 10px;
                    @media (min-width:$screen-lg-min){
                        margin-top: 0;
                    }
                    a {
                        color: $black;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        p {
            margin: 10px 0;
        }
    }

    .galleryGrid {
        @include speed();
        @include breakpoint(sm) {
            position: fixed;
            top: 10vh;
            right: 0;
            left: auto;
        }
        &.menu-open {
            right: 300px;
        }
        .ggWrap {
            padding: 0px;
            height: 45vh;
            @media (min-width:$screen-xs-min){
                height: 90vh;
            }
            > .row {
                height: 100%;
            }
            video {
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                z-index: -1000;
                overflow: hidden;
            }
            .mainFocus {
                height: 70%;
                padding:0px;
                .mainSlider {
                    height: 100%;
                    .flex-viewport {
                        height: 100%;
                        .slides {
                            height: 100%;
                            li {
                                height: 100%;
                                background-size: cover;
                                background-position: 50%;
                            }
                        }
                    }
                }
            }
            .sliderSection {
                height: 100%;
                @media (min-height:768px){
                    height: 30%;
                }
                @media (min-width:$screen-sm-min){
                    height: 30%;
                }
                padding:0px;
                .flexslider {
                    height: 100%;
                    .flex-viewport {
                        height: 100%;
                        ul.slides {
                            height: 100%;
                            li {
                                height: 100%;
                                background-size: cover;
                                position: relative;
                                a {
                                    position: absolute;
                                    bottom: 0px;
                                    left: 0px;
                                    right: 0px;
                                    top: 0px;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 9999;
                                }
                                div {
                                    width: 100%;
                                    height: 20%;
                                    background-color: transparentize($brand-primary, 0.3);
                                    position: absolute;
                                    bottom: 0px;
                                    left: 0px;
                                    transition: 0.5s all ease;
                                    right: 0px;
                                    h3 {
                                        text-align: center;
                                        color: #fff;
                                        font-style: italic;
                                        margin: 0;
                                        padding: 10px 0 0;
                                        transition: 0.5s all ease;
                                        font-size: 18px;
                                        font-weight: 100;
                                        line-height: 28px;
                                        @media (min-width:$screen-lg-min){
                                            font-size: 25px;
                                        }
                                    }
                                    p {
                                        padding: 20px;
                                        color: #fff;
                                        text-align: center;
                                    }
                                    a {
                                        display: block;
                                        height: auto;
                                        width: 120px;
                                        max-width: 100%;
                                        margin: 0px auto;
                                        padding: 10px;
                                        transition: 0.5s all ease;
                                        color: #fff;
                                        position: relative;
                                        text-align: center;
                                        z-index: 9999;
                                        &:hover {
                                            cursor: pointer;
                                            border: 1px solid #fff;
                                            text-decoration: none;
                                        }
                                    }
                                    @media (max-width: $screen-sm-min - 1){ // Trigger hover state on mobiles.
                                        height: 100%;
                                        h3 {
                                            padding: 25px 0 0;
                                        }
                                    }
                                }
                                &:hover {
                                    div {
                                        height: 100%;
                                        h3 {
                                            padding: 25px 0 0;
                                        }
                                    }
                                }
                                &.weddings {
                                    div {
                                        background-color: transparentize($brand-secondary, 0.3);
                                        a {
                                            background-color: $brand-secondary;
                                            border: 1px solid $brand-secondary;
                                            &:hover {
                                                background-color: darken($brand-secondary, 15%);
                                            }
                                        }
                                    }
                                }
                                &.accommodation {
                                    div {
                                        background-color: transparentize($brand-primary, 0.3);
                                        a {
                                            background-color: $brand-primary;
                                            border: 1px solid $brand-primary;
                                            &:hover {
                                                background-color: lighten($brand-primary, 15%);
                                            }
                                        }
                                    }
                                }
                                &.dining {
                                    div {
                                        background-color: transparentize($brand-tertiary, 0.3);
                                        a {
                                            background-color: $brand-tertiary;
                                            border: 1px solid $brand-tertiary;
                                            &:hover {
                                                background-color: darken($brand-tertiary, 15%);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &#contentBox {
                > .row {
                    @include breakpoint(sm) {
                        height: 100%;
                        position: absolute;
                        width: 100%;
                        top: 0px;
                        left: 15px;
                        right: 15px;
                        bottom: 0px;
                        /* transition: 2s all ease;
                        opacity: 0; */
                    }
                }
                .row {
                    transition: 0.75s all ease;
                }
                /*                .gallerySections__2, .gallerySections__3 {
                                    z-index: 0;
                                    opacity: 0;
                                    @include fader();
                                }*/
                @media (min-width:$screen-sm-min){
                    &.section1 {
                        .gallerySections__1 {
                          //  z-index: 999;
                            opacity: 1 !important;
                         //   @include fader();
                         a{
                             pointer-events: auto;
                         }
                        }
                        .gallerySections__2 {
                         //   z-index: -1;
                            opacity: 0;
                            pointer-events: none;
                            a{
                             pointer-events: auto;
                            } 
                        }
                        .gallerySections__3 {
                        //    z-index: -1;
                            opacity: 0;
                            pointer-events: none;
                            a{
                             pointer-events: auto;
                            }
                        }
                    }
                    &.section2,
                    &.section3 {
                        .gallerySections__1 {
                        //    z-index: 99;
                        }
                        .gallerySections__2 {
                         //   z-index: 999;
                            opacity: 1 !important;
                            @include fader();
                        }
                        .gallerySections__3 {
                        //    z-index: -1;
                            opacity: 0;
                        }
                    }
                    &.section3 {
                        .gallerySections__1 {
                         //   z-index: -1;
                            opacity: 0;
                        }
                        .gallerySections__2 {
                         //   z-index: 99;
                        }
                        .gallerySections__3 {
                         //   z-index: 999;
                            opacity: 1 !important;
                            @include fader();
                        }
                    }
                }
                .gallerySections {
                    &.default, &.fullSlider {
                        opacity: 1;
                        z-index: 1;
                    }
                    &.fullSlider {
                        > div {
                            padding: 0;
                            height: 100%;
                            .mainSlider, .flex-viewport, .slides, .slides li {
                                height: 100%;
                            }
                        }
                    }
                }
            }
            
            
            
            
            /* Weddings */
            .imageBlock {
                background-color: $brand-quaternary; 
                background-size: cover; 
                background-position: 50% 50%; 
            }
            .grid_four_images { // Original Grid of four images
                                height: 100%;
                                .row {
                                    height: 100%;
                                    .box_70_height {
                                        height: 70%;
                                    }
                                    .box_30_height {
                                        height: 30%;
                                    }
                                }
            }
            .grid_five_images { // The above Grid of four images reversed
                                height: 100%;
                                .row {
                                    height: 100%;
                                    .box_50_height {
                                        height: 50%;
                                    }
                                    .box_30_height {
                                        height: 30%;
                                    }
                                    .box_40_height {
                                        height: 40%;
                                    }
                                }
            }
			.grid_four50x50_images { // The above Grid of four images reversed
                                height: 100%;
                                .row {
                                    height: 100%;
                                    .box_50_height {
                                        height: 50%;
                                    }
                                }
								}
			
        }
    }

}

hr {
    border-top: 1px solid #cdc9c5;
}


.dcsns-twitter .section-text img {display:block!important;}


.nav-links{
    float: left;
    margin-bottom: $grid-gutter-width;
    width: 100%;
    >div {
      width: 50%;
      float: left;
      text-align: left;
      &+div{
          text-align: right;
      }
      >a{
          @extend .btn;
          
          border-radius: 0;
          border: 1px solid $brand-primary-lighter;
          &:after,
          &:before{
              font-family: 'fontawesome';
          }
      }
      &.nav-previous>a:before{
          content: '\f104';
          padding-right: 5px;
      }
      &.nav-next>a:after{
          content: '\f105';
          padding-left: 5px;
      }
  }
  
}

.single-person{
    .balbirnieMasterWrap {
        .galleryGrid {
            .ggWrap {
                .imageBlock{
                    background-position:top;
                }
            }
        }
    }
}



/* ===============

        END

=============== */