
.excerpt{
    @media (min-width: $screen-sm-min){
        height: 270px;
        float: left;
        width: 100%;
        position: relative;
        .read-more{
            bottom: 0;
            position: absolute;
        }
    }
    @media (min-width: $screen-md-min){
        height: 220px;
    }
    @media (min-width: $screen-lg-min){
        height: 200px;
    }
}