.balbirnieMasterWrap {
    .contentGrid {
        .cgWrap {
            .homepageButtons {
                display: flex;
                flex-wrap: wrap;
                >div{
                    flex-grow: 1;
                    float: none;
                    margin-bottom: 15px;
                    a{
                        height: 100%;
                    }
                }
                @include breakpoint(xs) {
                    margin: 20px auto !important;
                }
                @include breakpoint(sm) {
                    margin: 20px -15px !important;
                }
            }
            // People & People Archive
				.departmentLink {
					@include speed();
					color: #333;
					&:hover {
						text-decoration: none;
						color: #8a8a8a;
					}
				}
				.personDetails {
					h3 {
						font-size: 24px;
						margin: 15px 0 10px;
						text-align: left;
						min-height: 50px;
					}
					p {
						font-size: 18px;
						color: #666;
						margin: 0 0 10px;
						&.position {
							min-height: 50px;
							text-align: left;
						}
					}
				}
            // End
			// Team Taxonomy 
				.team_title {
					margin: 0 0 25px;
				}
			// End
        }
    }
}

.archive .cgWrap  {
	width: 100% !important;
}

.wall-outer{
    .dc-wall{
        .stream{
            width: 224px;
            @media (min-width:$screen-xs-min){
                width: 466px;
            }
            @media (min-width:$screen-sm-min){
                width: auto;
            }
            li{
                min-width: 224px; 
                @media (min-width:$screen-sm-min){
                    min-width: 46%;
                }
                @media (min-width:$screen-md-min){
                 min-width: 30%; 
                }
                @media (min-width:$screen-lg-min){
                 min-width: 23%; 
                }
            }
        }
    }
}

.btn-wed-plugin-1{
   background-color: #422c89;
   border-color: #422c89;
   border-radius: 0;
   font-weight: 600;
   width: 100%;
}
.btn-wed-plugin-2{
    background-color: #27ae60;
    border-color: #27ae60;
    border-radius: 0;
    font-weight: 600;
    width: 100%;
}

.modal-flex{
    display: flex;
    align-items: center;
}