// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  display: inline;
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.wpcf7-form-control{
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204,204,204);
    max-width: 100%;
    width: 100%;
}

.btn-submit, .wpcf7-submit{
    @extend .btn;
    @extend .btn-default;
}

.wpcf7-form{
    label{
        width: 100%;
    }
}

// MC form

#mc_embed_signup{
    .mc-field-group{
        margin-bottom: 10px;
        input{
            display: block;
            padding: 10px;
            width: 100%;
        }
    }
    .mc_fieldset{
        .checkbox.subfield{
            padding-left: 20px;
        }
    }
}