/* Glyphicons font path */
$icon-font-path:        "../fonts/";

/* Grid settings */
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

/* Fonts */

$main-font: 'Open Sans', sans-serif !important;

$adobeFont: "adobe-text-pro",sans-serif;

@font-face {
    font-family: 'linecons';
    src:url('../fonts/linecons.eot');
    src:url('../fonts/linecons.eot?#iefix') format('embedded-opentype'),
        url('../fonts/linecons.woff') format('woff'),
        url('../fonts/linecons.ttf') format('truetype'),
        url('../fonts/linecons.svg#linecons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon:before {
    font-family: 'linecons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    margin-right: 0.6em;
    -webkit-font-smoothing: antialiased;
}
.icon-arrow-right:before {
    content: "\e035";
}

/* Branding */

$white: #fff;
$black: #121212;

$brand-primary: #071635; /* Blue */
$brand-primary-light: lighten($brand-primary, 15%);
$brand-primary-lighter: lighten($brand-primary, 25%);
$brand-primary-darkish: darken($brand-primary, 5%);
$brand-primary-dark: darken($brand-primary, 15%);

$brand-secondary: #422c89; /* Purple */
$brand-secondary-light: lighten($brand-secondary, 15%);
$brand-secondary-dark: darken($brand-secondary, 15%);

$brand-tertiary: #27ae60; /* Aqua */
$brand-tertiary-light: lighten($brand-tertiary, 15%);
$brand-tertiary-dark: darken($brand-tertiary, 15%);

$brand-quaternary: #e1ddd8;
$brand-quaternary-light: lighten($brand-quaternary, 15%);
$brand-quaternary-dark: darken($brand-quaternary, 15%);

$brand-quinary: #fef0ff;
$brand-quinary-light: lighten($brand-quinary, 15%);
$brand-quinary-dark: darken($brand-quinary, 15%);

$brand-senary: #ffc33c;
$brand-senary-light: lighten($brand-senary, 15%);
$brand-senary-dark: darken($brand-senary, 15%);

/* Respond */
@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: 767px) { @content; }
    }
    @else if $class == sm {
        @media (min-width: 768px) { @content; }
    }
    @else if $class == mdm {
        @media (min-width: 768px) and (max-width: 992px) { @content; }
    }
    @else if $class == md {
        @media (min-width: 992px) { @content; }
    }
    @else if $class == lg {
        @media (min-width: 1200px) { @content; }
    }
    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

/* Fade those panels in! */

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

@mixin fader() {
    .fade-in {
        opacity:0;  /* make things invisible upon start */
        -webkit-animation:fadeIn ease-in-out  1;  /* call our keyframe named fadeIn, use animation ease-in and repeat it only 1 time */
        -moz-animation:fadeIn ease-in-out 1;
        animation:fadeIn ease-in-out 1;
        -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
        -moz-animation-fill-mode:forwards;
        animation-fill-mode:forwards;
        -webkit-animation-duration: 0.5s;
        -moz-animation-duration: 0.5s;
        animation-duration: 0.5s;
    }

    .fade-in.one {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .fade-in.two {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        animation-delay: 0.4s;
    } 
    .fade-in.three {
        -webkit-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    .fade-in.four {
        -webkit-animation-delay: 0.8s;
        -moz-animation-delay: 0.8s;
        animation-delay: 0.8s;
    }
    .fade-in.five {
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
        animation-delay: 1s;
    }
}

/* Typography */

html {
    p {
        font-size: 18px;
    }
    body {
        /* font-family: $main-font; */
    }
}


.section {
    .mainStrip {
        .contentTxt, .newsPans {
            h1, h2, h3, h4, h5 {
                color: $brand-primary;
                a{ color: $brand-primary; }
            }
        }
    }
}

h1 {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        font-size: 30px;
    }
}

h2 {
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        font-size: 20px;
    }
}

h3 {

}

h4 {

}

h1, h2, h3 {
    margin: 0px 0px 15px;
}

strong {
    color: $brand-primary;
}

p {
    line-height: 25px;
}

/* Generic Transitions */

@mixin speed() {
    transition: 0.5s all ease;
}

.fullHeight {
    height: 100%;
}

/* Generic Buttons */

/* =================

        Button Controls:
        
        .butn = Apply to any button you wish to use the styles below.
        .fill = Brand colours w/o this buttons are transparent.
        .center = Center alignment w/o this, buttons are left aligned.

================= */

.butn {
    max-width: 100%;
    display: block;
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
    @include speed();
    &.sz {
        &__lrg {
            width: 250px;
        }
        &__sml {
            width: 145px;
        }
    }
    &.fill {
        &__blue {
            background-color: $brand-primary;
        }
        &__purp {
            background-color: $brand-secondary;
        }
        &__aqua {
            background-color: $brand-tertiary;
        }
    }
    &.center {
        margin: 0 auto;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
        &.fill {
            &__blue {
                background-color: lighten($brand-primary, 15%);
            }
            &__purp {
                background-color: darken($brand-secondary, 15%);
            }
            &__aqua {
                background-color: darken($brand-tertiary, 15%);
            }
        }

    }
}