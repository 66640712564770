/* =================

Slider Menu Styles Only:

================= */

//@include breakpoint(sm) {
    .menuMaster {
        .menulbl {
            position: absolute;
            top: -1px;
            right: 40px;
            color: #fff;
        }
        #nav-icon3 {
            width: 30px;
            height: 30px;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            @media (min-width:$screen-sm-min){
                margin: 8px auto 0;
            }
            @media (min-width:$screen-lg-min){
                 float: left;
                margin: 8px 0 0 8px;
            }
            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: #fff;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2), &:nth-child(3) {
                    top: 9px;
                }
                &:nth-child(4) {
                    top: 17px;
                }
            }
            &.open {
                span {
                    &:nth-child(1) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                    &:nth-child(2) {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &:nth-child(3) {
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                    &:nth-child(4) {
                        top: 10px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
//}

.scroller,
.scroller-inner {
    position: relative;
}

.container-menu {
    position: relative;
    background: #34495e;
}
.menu-trigger {
    z-index:3;
}
.mp-pusher {
    position: relative;
    right: 0;
}
#mp-menu {
    position: fixed;
    top: 0;
}



.mp-pusher{
    height: 100%;
    & ~ .closeNav{
        opacity: 0;
        background: rgb(51,108,166);
        box-shadow: 0 0 2px rgba(0,0,0,.2);
        cursor:pointer;
        width: 33px;
        height: 33px;
        padding: 6px 8px;
        display: block;
        border-radius: 50%;
        position: absolute;
        font-size: 21px !important;
        right: 20px;
        top:25px;
        z-index: 999;
        transform: scale(.25);
        transition: .33s all ease;
        @media (min-width:$screen-sm-min){	
            display: none;
        }
    }
    &.mp-pushed{
        &~ .closeNav{
            opacity: 1;
            transform: scale(1);
        }
    }

}

.mp-menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
    -webkit-transform: translate3d(+100%, 0, 0);
    -moz-transform: translate3d(+100%, 0, 0);
    -ms-transform: translate3d(+100%, 0, 0);

}
#mp-menu {
    top: 0;
}
.mp-level {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $brand-primary-light;
    -webkit-transform: translate3d(+100%, 0, 0);
    -ms-transform: translate3d(+100%, 0, 0);
    -moz-transform: translate3d(+100%, 0, 0);
    transform: translate3d(+100%, 0, 0);
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
    background: rgba(0,0,0,0.3);
    -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    -ms-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
}

.mp-level::after {
    z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.mp-level.mp-level-overlay {
    cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
}

.mp-pusher,
.mp-level {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
    box-shadow: 1px 0 2px rgba(0,0,0,0.2);
    -webkit-transform: translate3d(40px, 0, 0);
    -moz-transform: translate3d(40px, 0, 0);
    -ms-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
    -webkit-transform: translateZ(0);
}

/* First level */ /* IE BREAKER */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
    box-shadow: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
}

/* cover */
.mp-cover .mp-level.mp-level-open {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    -webkit-transform: translate3d(+100%, 0, 0);
    -moz-transform: translate3d(+100%, 0, 0);
    -ms-transform: translate3d(+100%, 0, 0);
    transform: translate3d(+100%, 0, 0);
    -webkit-transform: translateZ(0);
}

/* content style */
.mp-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 80vh;
    overflow: auto;
    background-color:  $brand-primary-light;
}

.mp-menu h2 {
    margin: 0;
    padding: 20px 40px 20px 20px;
    color: #fff;
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    font-weight: 300;
    font-size: 27px;
    position: relative;
    background-color: $brand-primary;
    height: 10vh;
    height: 122px;
    @media (min-width:$screen-sm-min){
        height: 95px;
    }
    .closeTab {
        position: absolute;
        top: 27px;
        right: 15px;
        font-size: 18px;
        color: #fff;
        padding: 10px;
        border-left: 1px solid #a3bcef;
       /* i {
            transition: 0.5s all ease;
            &.spinners {
                -webkit-transform:rotate(360deg);
                -moz-transform:rotate(360deg);
                -ms-transform:rotate(360deg);
                -o-transform:rotate(360deg);
                transform:rotate(360deg);
            }
        } */
        &:hover {
            cursor: pointer;
            color: $brand-senary;
        }
    }
}

.mp-menu.mp-overlap h2::before {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    font-size: 75%;
    line-height: 1.8;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.3s;
    -ms-transition: opacity 0.3s, -ms-transform 0.1s 0.3s;
    transition: opacity 0.3s, transform 0.1s 0.3s;
    -webkit-transform: translateX(+100%);
    -moz-transform: translateX(+100%);
    -ms-transform: translateX(+100%);
    transform: translateX(+100%);
}

.mp-menu.mp-cover h2 {
    background-color: $brand-primary;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1em;
}

.mp-overlap .mp-level.mp-level-overlay > h2::before {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.mp-menu {
    ul {
        li  { 
            > a {
                display: block;
                color:#fff;
                padding: 0.7em 1em 0.7em 1.8em;
                outline: none;
                box-shadow: inset 0 -1px rgba(255, 255, 255, 0.2);
                text-shadow: 0 0 1px rgba(255,255,255,0.1);
                font-size: 1.4em;
                -webkit-transition: background 0.3s, box-shadow 0.3s;
                -moz-transition: background 0.3s, box-shadow 0.3s;
                -ms-transition: background 0.3s, box-shadow 0.3s;
                transition: background 0.3s, box-shadow 0.3s;
            }
            a {
                position: relative;
                z-index: 2;
                font-family: $adobeFont;
            }
            &::before {
                position: relative;
                float: right;
                right: 10px;
                z-index: 0;
            }
            &:last-child{
                margin-bottom: 100px;
            }
        }
    }
}


.mp-menu ul li a:hover,
.mp-level > ul > li:first-child > a:hover {
    background: $brand-primary;
    box-shadow: inset 0 -1px hsla(0,0%,100%,.2);
    text-decoration: none;
    color: #FFC33C;
}

.mp-menu .mp-level.mp-level-overlay > ul > li > a,
.mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0);
}

.mp-level > ul > li:first-child > a:hover,
.mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0), inset 0 1px rgba(0,0,0,0);
} /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

.mp-back {
    background: $brand-primary-lighter;
    outline: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 13px;
    padding: 10px 0px 10px 35px;
    position: relative;
    box-shadow: inset 0 -1px hsla(0,0%,100%,.2);
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    transition: background 0.3s;
    &:hover {
        color: #fff !important;
    }
    &::after {
        font-family: linecons;
        position: absolute;
        content: "\e034";
        left: 5px;
        top: 3px;
        font-size: 20px;
        color: rgba(255,255,255,1);
    }
}

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
    background: transparent;
    box-shadow: none;
    color: transparent;
}

.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
    padding-right: 300px;
}

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
    display: none;
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
    display: block;
}

#mp-menu {
    .mp-level {
        .mp-level {
            &.mp-level-open {
                background: $brand-primary-light;
                z-index: 99999;
                ul {
                    background: $brand-primary-light;
                }
            }
        }
    }
}

.mp-menu ul li:before {
    color: #fff !important;
    line-height: 25px !important;
    font-size: 25px !important;
    top: 15px !important;
    margin-right: 0 !important;
    left: -5px!important;
    z-index: 99;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  and (orientation : landscape) { 
    .header {
        transform: translateZ(0);
        -moz-transform: translatez(0);
        -ms-transform: translatez(0);
        -o-transform: translatez(0);
        -webkit-transform: translateZ(0);
        -webkit-font-smoothing: antialiased; /* seems to do the same in Safari */
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
}

.mp-level.mp-level-overlay:after, .mp-pusher.mp-pushed:after {
    z-index: 9999;
    background: rgba(0,0,0,.6) !important;
}

.mp-pusher {
    transform: none !important;
    left: 0;
    &.mp-pushed {
        left: -300px;
    }
    #mp-menu {
        z-index: 999999;
        .mp-level {
            z-index: 99999;
        }
        .mp-level.first.mp-level-open {
            right: 300px;
            transition: 0.5s all ease;
        }
    }
}