.brand{
    @media(max-width:$screen-sm-min - 1){
        display: block;
        padding-top: 10px;
       // text-align: center;
    }
    .img-responsive{
        display: inline-block;
    }
}
#headerPart {
    @include breakpoint(sm) {
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100%;
    }
    .banner {

    }
    .bookings {
        @include speed();
        display: block;
        width: 100%;
        padding: 10px 5px;
        border: 1px solid #355494;
        text-align: center;
        color: #fff;
        font-family: $adobeFont;
        font-size: 13px;
        margin-top:25px; 
        margin-bottom: 10px;
        @media(min-width:$screen-md-min){
            padding: 10px;
            font-size: 15px;
        }
        @media(min-width:$screen-lg-min){
            font-size: 18px;
            margin-top:23px; 
        }
        @include breakpoint(sm) {
            //font-size: 18px;
            //margin-top:23px; 
        }
        &:hover {
            text-decoration: none;
            background-color: #355494;
        }
    }
}

.mp-level.mp-level-overlay:after, .mp-pusher.mp-pushed:after {
    position: fixed; 
}
.mp-level.mp-level-overlay:after, .mp-pusher.mp-pushed:after{
    z-index: 9990;
}

.mp-pusher.mp-pushed {
    .balbirnieMasterWrap header.banner .brand img {
        @media (min-width:$screen-lg-min + 300){
            margin: 30px 0px 30px 360px;
        }
    }
}

.mp-menu >div>p{
    box-shadow: 0 -1px rgba(255, 255, 255, 0.2) inset;
    margin-bottom: 0;
    padding: 0.7em 1em 0.7em 1.8em;
    
    &.copyright{
        box-shadow: none;
        font-size: 14px;
        color: lighten(#355494,33%);
        position: absolute;
        bottom: 40px;
    }
}

@media (max-width:992px){
    .mp-menu ul li>a{

    }
}


.header-google-translate-holder{
    margin:24px auto -15px auto;
    position:relative;

    .switcher{

        border:1px solid #355494;
        float:right;

        .selected{

            background:#071635;

            a{
                font-family: adobe-text-pro, sans-serif;
                background:#071635;
                color: white;
                font-size:18px;
                width:100%;
                padding:13px 10px;
                border:none;

                &:hover, &:focus, &:active{
                    background:#355494;
                }

            }
        }

        .option {
            background: #071635;
            border:1px solid #355494;
            position: absolute;

            a{
                color:white;
                padding:14px;
                max-height: 100px;
                font-family: adobe-text-pro, sans-serif;

                &:hover, &:focus, &:active {
                    background: #355494;
                }
            }

            a.selected {
                background:#355494;
            }
        }
    }
}

@media (max-width:1200px){
    .header-google-translate-holder {
        display: none;
    }
}



.menu-google-translate-holder{
    display:none;
}

@media (max-width:1200px) {
    .menu-google-translate-holder {

        display:block;

        .switcher {

            width: 100%;

            .selected a {
                border: none;
                color: white;
                width: 100%;
                background: #103279 !important;
                padding: .7em 1em .7em 1.8em;
                box-shadow: inset 0 -1px hsla(0, 0%, 100%, .2);
                font-family: adobe-text-pro, sans-serif;
                font-size: 1.4em;

                &:hover {
                    background: #071635;
                    color: #ffc33c;
                }
            }

            .option {
                background: #103279 !important;
                max-height: 100px;
                width: 100%;
                font-family: adobe-text-pro, sans-serif;
                font-size: 1.4em;
                border: none;

                a {
                    padding: .7em 1em .7em 1.8em;
                    color: white;

                    &:hover, &:focus, &:active {
                        background: #071635;
                        color: #ffc33c;
                    }
                }

                a.selected {
                    background: #071635;
                    color: #ffc33c;
                }
            }

        }
    }
}
