.cover-link {
    display:block; 
    position: absolute; 
    top:0;
    right:0;
    left:0;
    bottom:0;
    background: transparent;
    transition: 0.3s background ease;
    .fa {
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
    }
    &:hover {
        background: rgba(95,64,194,0.6);
    }
}