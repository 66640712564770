.mp-menu>div>p.copyright {
   box-shadow: none;
   font-size: 14px;
   color: #97adda  !important;
   position: absolute;
   bottom: 40px;
   left: 0;
   right: 0;
   text-align: center;
   padding: 0px;
	a {
	   display: block;
	   color: #5a7ec5 ;
	}
}

footer.content-info{
    background: $brand-primary;
    margin-left: - $grid-gutter-width / 2;
    margin-right: - $grid-gutter-width / 2;
    margin-top: $grid-gutter-width;
    padding: $grid-gutter-width / 2  0  $grid-gutter-width;
}
.footer-wrap{
    color: #fff;
    width: 100%;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    position: relative;
    z-index: 900;
    @media (min-width:$screen-sm-min){
        padding-left: 65px;
        padding-right: 65px;
        width: 50%;
    }
    @media (min-width:$screen-md-min){
        width: 41.6667%;
    }
    p{
        font-size: 17px;
        + p+p{
            font-size: 15px;
        }
    }
    
    p, li{
        a{
          &:hover,
          &:focus{
              color: rgb(71,142,203);
          }  
        }
    }
    ul{
        font-family: "adobe-text-pro",serif;
        float: left;
        list-style: none;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 0;
        width: 100%;
        li{
            display: inline-block;
            float: left;
            padding-right: 10px;
            text-align: center;
            min-width: 50%;
            a{
                display: block;
            }
            +li{
                border-left: 1px solid rgb(51, 122, 183);
                padding-left: 10px;
             //   padding-right: 0;
            }
            &:last-child{
                padding-right: 0;
            }
        } 
    }
   
    .bookings {
        @include speed();
        display: block;
        width: 100%;
        padding: 10px 5px;
        border: 1px solid #1d2e4a;
        text-align: center;
        color: #fff;
        font-family: $adobeFont;
        font-size: 17px;
        margin-bottom: 15px;
        @media(min-width:$screen-md-min){
            padding: 10px;
            font-size: 15px;
        }
        @media(min-width:$screen-lg-min){
            font-size: 18px;
            margin-top:23px; 
        }
        @include breakpoint(sm) {
            //font-size: 18px;
            //margin-top:23px; 
        }
        
            text-decoration: none;
            background-color: #1d2e4a;
        
    }
}